import https from "./https";
import store from "@/store";

const merchant = {
  async list() {
    let response = {}
    const organization = store.state.liffGeneral.orgCode;
    response = await https.get(`${organization}/liff/merchants`);
    return response.data
  },
};

export default merchant;
